import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conv-popover-component',
  templateUrl: './conv-popover-component.component.html',
  styleUrls: ['./conv-popover-component.component.scss'],
})
export class ConvPopoverComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
