<ion-header>
  <ion-toolbar color="light">
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="primary" size="large">
        <ion-icon name="chevron-back-outline">
        </ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" color="primary">
      {{_chat?.first_name}} {{_chat?.last_name}}
    </ion-title>
    <ion-buttons slot="end" hidden>
      <ion-icon name="ellipsis-vertical-outline" color="medium" (click)="presentPopover($event)" size="large">
      </ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <div id="chatarea">
          <ion-item *ngFor="let message of _chat?.messages" lines="none"
            [color]="message.message_direction == 'IN' ? 'light' : ''" class="message-out">
            <ng-container *ngIf="message.message_direction == 'IN'; else elseTemplate">
              <ion-icon slot="start" color="primary" name="chatbubbles-outline"></ion-icon>
              <ion-label class="ion-text-wrap">
                <h2 class="inmessage">{{message.message_text}}</h2>
                <p class="time-text">{{message.date_created | dateAgo}}
                </p>
              </ion-label>
            </ng-container>
            <ng-template #elseTemplate>
              <ion-label class="ion-text-wrap">
                <h2 class="ion-text-end">{{message.message_text}}</h2>
                <p class="time-text ion-text-end">{{message.date_created | dateAgo}}
                </p>
              </ion-label>
              <ion-icon slot="end" color="medium" name="chatbubbles-outline"></ion-icon>
            </ng-template>
          </ion-item>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ng-container *ngIf="_chat?.assigned_user_id == null; else startButtonTemplate">
    <ion-button fill="clear" color="medium" (click)="claimChat()" expand="full">
      <ion-icon name="footsteps-outline" slot="start" color="primary"></ion-icon>
      Step into conversation
    </ion-button>
  </ng-container>
  <ng-template #startButtonTemplate>
    <ng-container *ngIf="_chat?.assigned_user_id === _user?.id; else takenTemplate">
      <ion-item lines="none">
        <ion-textarea #messageta label="message" autoGrow="true" spellcheck="true" wrap="hard" placeholder="...reply"
          [(ngModel)]="_message" autocapitalize="true" rows="1">
        </ion-textarea>
        <ion-button fill="clear" color="primary" slot="end" (click)="sendMessage()" shape="round">
          <ion-icon name="send-outline" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-item>
    </ng-container>
  </ng-template>
  <ng-template #takenTemplate>
    <ion-item lines="none">
      This Chat-session has been taken.
    </ion-item>
  </ng-template>
</ion-footer>