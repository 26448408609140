import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  IonTextarea,
  ModalController,
  PopoverController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { Chat } from "src/app/classes/chat/chat";
import { AppState } from "src/app/ngrx";
import { LaPopoverComponent } from "../lead-accordian/la-popover/la-popover.component";
import { ConvPopoverComponentComponent } from "./ConvPopoverComponent/conv-popover-component/conv-popover-component.component";
import * as fromSessionSelectors from "src/app/ngrx/session/session.selectors";
import * as SessionActions from "src/app/ngrx/session/session.actions";
import { Subject } from "rxjs";
import { catchError, first, single, takeUntil, tap } from "rxjs/operators";
import { ChatMessage } from "src/app/classes/chat/chat-message";
import { ChatService } from "src/app/services/data/chats/chat.service";
import { Actions, ofType } from "@ngrx/effects";
import { User } from "src/app/classes/user/user";
import { WsMessagingService } from "src/app/services/ws-messaging.service";



@Component({
  selector: "app-conversation",
  templateUrl: "./conversation.component.html",
  styleUrls: ["./conversation.component.scss"]
})
export class ConversationComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line: variable-name
  public _chat: Chat;
  // tslint:disable-next-line: variable-name
  public _message: string;
  // tslint:disable-next-line: variable-name
  public _user: User;

  @ViewChild("messageta", { static: false }) messagetaElement: IonTextarea;

  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @Input() set chat(val: Chat) {
    // console.log(val);
    this._chat = val !== undefined && val !== null ? val : new Chat();
  }
  constructor(
    // private socket: Socket
    private store: Store<AppState>,
    public popoverController: PopoverController,
    public modalController: ModalController,
    private updates$: Actions,
    public chatService: ChatService,
    private wsMessagingService: WsMessagingService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    if (window.history.state.modal) {
      history.back();
    }
  }

  ionViewDidEnter() {
    this.wsMessagingService.joinRoom(this._chat.id);

    this.store.dispatch(
      SessionActions.lazyLoadChatMessages({ chat: this._chat })
    );

    this.store
      .select(fromSessionSelectors.SelectCurrentUser)
      .pipe(first())
      .subscribe((user) => {
        this._user = user;
      });

    const modalState = {
      modal: true,
      desc: "placeholder state for modal",
    };
    history.pushState(modalState, null);

    this.store
      .select(fromSessionSelectors.SelectChats)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        complete: () => {},
        error: (err) => {
          console.log(err);
        },
        next: (c) => {
          this._chat = c.find((i) => i.id === this._chat.id);
          this.scrollToBottomOfchat();
        },
      });

    this.updates$
      .pipe(ofType(SessionActions.LogoutUser), takeUntil(this.ngUnsubscribe))
      .subscribe((props) => {
        this.dismiss();
      });

    this.updates$
      .pipe(
        ofType(SessionActions.NewChatMessage),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        complete: () => {},
        error: (err) => {
          console.log(err);
        },
        next: (m) => {
          this._chat.messages.concat(m.message);
          this.scrollToBottomOfchat();
        },
      });

    /* this.updates$.pipe(ofType(SessionActions.WSConnectionOpen), takeUntil(this.ngUnsubscribe)).subscribe({
      complete: () => { },
      error: (err) => { console.log(err); },
      next: () => {
        
      }
    }); */

    this.updates$
      .pipe(
        ofType(SessionActions.AssignChatSuccess),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe({
        complete: () => {},
        error: (err) => {
          console.log(err);
        },
        next: (props) => {
          const msg = new ChatMessage({
            id: "",
            date_created: Date().toString(),
            message_direction: "OUT",
            chat_id: props.chat.id,
            message_text:
              "👋 ==> " + props.user.first_name + " has just entered the chat.",
            date_edited: Date(),
          });

          this.chatService.sendMessage(msg).subscribe({
            complete: () => {},
            error: (err) => console.log("[Conversation component] Error:", err),
            next: () => {},
          });
        },
      });
    /* 
        this.wsMessagingService.messages$.pipe(
          takeUntil(this.ngUnsubscribe),
          catchError(error => { throw error; })
        ).subscribe({
          next: (msg) => {
            const message = new ChatMessage(msg as object);
            this.store.dispatch(SessionActions.NewChatMessage({ message }));
            if (message.message_direction === 'IN') {
              const audio = new Audio('./../../../assets/sounds/juntos-607.mp3');
              audio.play();
            }
            this.scrollToBottomOfchat();
          },
          error: error => console.log('[Conversation component] Error:', error),
          complete: () => console.log('[Conversation component] Connection Closed'),
        }); */
  }

  ionViewWillLeave() {
    this.wsMessagingService.leaveRoom(this._chat.id);
  }

  private resetMessage() {}

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: ConvPopoverComponentComponent,
      componentProps: {
        // lead: this._chat
      },
      event: ev,
    });

    popover.onDidDismiss().then((result) => {
      if (result.data === "close") {
        this.dismiss();
      }
    });

    return await popover.present();
  }

  async scrollToBottomOfchat() {
    setTimeout(async () => {
      const chatarea = document.getElementById("chatarea");
      if (chatarea != null) {
        chatarea.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      if (this.messagetaElement !== undefined) {
        this.messagetaElement.setFocus();
        const nt = await this.messagetaElement.getInputElement();
        nt.focus();
        nt.setSelectionRange(0, 0);
      }
    }, 100);
  }

  @HostListener("window:popstate", ["$event"])
  dismiss() {
    this.modalController.dismiss();
  }

  async sendMessage() {
    // this.socket.emit('message', this._message );
    const msg = new ChatMessage({
      id: "",
      date_created: Date().toString(),
      message_direction: "OUT",
      chat_id: this._chat.id,
      message_text: this._message,
      date_edited: Date(),
    });

    this.chatService.sendMessage(msg).subscribe({
      complete: () => {
        this._message = "";
      },
      error: (err) => console.log("[Conversation component] Error:", err),
      next: () => {},
    });
  }

  async claimChat() {
    this.store.dispatch(
      SessionActions.AssignChat({ chat: this._chat, user: this._user })
    );
  }
}
