import { ChatMessage } from './chat-message';

export class Chat {
    id: string;
    // tslint:disable-next-line:variable-name
    date_created: string;
    senderps: string;
    stage: string;
    sequence: string;
    // tslint:disable-next-line:variable-name
    date_edited: string;
    // tslint:disable-next-line:variable-name
    account_id: string;
    // tslint:disable-next-line:variable-name
    first_name: string;
    // tslint:disable-next-line:variable-name
    last_name: string;
    need: string;
    medicare: string;
    callortext: string;
    phonenumber: string;
    messages: ChatMessage[];
    // tslint:disable-next-line: variable-name
    assigned_user_id: string;
    // tslint:disable-next-line: variable-name
    contact_id: string;
    // tslint:disable-next-line: variable-name
    campaign_id: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
        this.messages = new Array<ChatMessage>();
    }

}
