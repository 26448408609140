import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestResponse } from 'src/app/classes/rest-response/rest-response';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Chat } from 'src/app/classes/chat/chat';
import { ListData } from 'src/app/classes/list-data/list-data';
import { map } from 'rxjs/operators';
import { ChatMessage } from 'src/app/classes/chat/chat-message';
import { User } from 'src/app/classes/user/user';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private httpClient: HttpClient) { }

  public getChats(): Observable<RestResponse> {
    return this.httpClient.get<RestResponse>(environment.apiUrl + '/rest/chats', { withCredentials: true });
  }

  public getChatMessages(chat: Chat): Observable<ListData> {
    return this.httpClient.get<RestResponse>(environment.apiUrl + '/rest/chat/' + chat.id + '/messages', { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        const listdata: ListData = new ListData(resp.data);
        return listdata;
      }));
  }

  public sendMessage(chatmessage: ChatMessage): Observable<boolean> {
    return this.httpClient
      .post<object>(environment.apiUrl + '/rest/chat/' + chatmessage.chat_id + '/message',
        { chatmessage }, { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        return resp.result;
      }));
  }

  public assignChat(chat: Chat, user: User): Observable<boolean> {
    return this.httpClient
      .post<object>(environment.apiUrl + '/rest/chat/' + chat.id + '/assign',
        { user }, { withCredentials: true })
      .pipe(map((resp: RestResponse) => {
        return resp.result;
      }));
  }

}
