export class ChatMessage {
    id: string;
    // tslint:disable-next-line:variable-name
    date_created: string;
    // tslint:disable-next-line:variable-name
    message_text: string;
    // tslint:disable-next-line:variable-name
    message_direction: string;
    // tslint:disable-next-line:variable-name
    chat_id: string;
    // tslint:disable-next-line:variable-name
    date_edited: string;

    constructor(values: object = {}) {
        Object.assign(this, values);
    }
}
